
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
// import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
// import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
// import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
// import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
// import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
// import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
// import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
// import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
// import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
// import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";

export default defineComponent({
  name: "dashboard",
  components: {
    // TableWidget9,
    // TableWidget5,
    // ListWidget1,
    // ListWidget2,
    // ListWidget3,
    // ListWidget5,
    // ListWidget6,
    // MixedWidget2,
    // MixedWidget5,
    MixedWidget7,
    // MixedWidget10,
  },
  setup() {
    const store = useStore();
    const isLoadingOrdersReport = ref(true);
    const isLoadingPromotionReport = ref(true);
    const countFoodOrder = ref(0);
    const countMessengerOrder = ref(0);
    const salePromotionSummary = ref(0);
    const startDate = ref(moment().startOf("month").format("YYYY-MM-DD"));
    const endDate = ref(moment().endOf("month").format("YYYY-MM-DD"));
    async function fetchOrderFoodReport() {
      const config = {
        startDate: startDate.value,
        endDate: endDate.value,
        orderType: 1,
        orderStatus: 0,
      };
      let districtList = JSON.parse(
        window.localStorage.getItem("user_district") || "[]"
      );
      let result = await store.dispatch(Actions.GET_ORDER_REPORT, {
        credentials: { district: districtList.map((val) => val.district_id) },
        configOrder: config,
      });
      countFoodOrder.value = result.data.length;
      isLoadingOrdersReport.value = false;
    }

    async function fetchOrderMessengerReport() {
      const config = {
        startDate: startDate.value,
        endDate: endDate.value,
        orderType: 2,
        orderStatus: 0,
      };
      let districtList = JSON.parse(
        window.localStorage.getItem("user_district") || "[]"
      );
      let result = await store.dispatch(Actions.GET_ORDER_REPORT, {
        credentials: { district: districtList.map((val) => val.district_id) },
        configOrder: config,
      });
      countMessengerOrder.value = result.data.length;
      isLoadingOrdersReport.value = false;
    }

    async function fetchPromotionRepot() {
      const config = {
        startDate: startDate.value,
        endDate: endDate.value,
      };
      let districtList = JSON.parse(
        window.localStorage.getItem("user_district") || "[]"
      );
      const result = await store.dispatch(Actions.GET_PROMOTION_REPORT, {
        credentials: { district: districtList.map((val) => val.district_id) },
        configPromotion: config,
      });
      //console.log(result);
      salePromotionSummary.value = result.data.reduce(
        (partial_sum, a) => partial_sum + a.total_paid_price,
        0
      );
      isLoadingPromotionReport.value = false;
    }

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      fetchOrderFoodReport();
      fetchOrderMessengerReport();
      fetchPromotionRepot();
    });
    return {
      isLoadingOrdersReport,
      isLoadingPromotionReport,
      countFoodOrder,
      countMessengerOrder,
      salePromotionSummary,
      startDate,
      endDate,
    };
  },
});
